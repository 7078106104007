<template>
  <v-card>
    <v-card-title>Track Detail</v-card-title>
    <v-row class="ma-0 pb-5 px-2">
      <v-col
        cols="6"
        md="4"
      >
        <span class="font-weight-bold">
          Title
        </span>
      </v-col>
      <v-col
        cols="6"
        md="8"
      >
        <p class="mb-0">
          {{ detail.title }}
        </p>
      </v-col>

      <v-col
        cols="6"
        md="4"
      >
        <span class="font-weight-bold">
          Length Seconds
        </span>
      </v-col>
      <v-col
        cols="6"
        md="8"
      >
        <p
          v-if="detail.lengthSeconds !== null"
          class="mb-0"
        >
          {{ detail.lengthSeconds }} sec.
        </p>
      </v-col>

      <v-col
        cols="6"
        md="4"
      >
        <span class="font-weight-bold">
          Type
        </span>
      </v-col>
      <v-col
        cols="6"
        md="8"
      >
        <p class="mb-0">
          {{ detail.type }}
        </p>
      </v-col>

      <v-col
        cols="6"
        md="4"
      >
        <span class="font-weight-bold">
          videoId
        </span>
      </v-col>
      <v-col
        cols="6"
        md="8"
      >
        <p class="mb-0">
          {{ detail.videoId }}
        </p>
      </v-col>

      <v-col
        cols="6"
        md="4"
      >
        <span class="font-weight-bold">
          Langs
        </span>
      </v-col>
      <v-col
        cols="6"
        md="8"
      >
        <p class="mb-0">
          {{ langs[detail.lang] }}
        </p>
      </v-col>

      <v-col
        cols="6"
        md="4"
      >
        <span class="font-weight-bold">
          Alternative Langs
        </span>
      </v-col>
      <v-col
        cols="6"
        md="8"
      >
        <p
          v-for="(lng, index) of detail.alternativeLangs"
          :key="index"
          class="mb-2"
        >
          {{ langs[lng] }}
        </p>
      </v-col>

      <v-col
        cols="6"
        md="4"
      >
        <span class="font-weight-bold">
          MP3
        </span>
      </v-col>
      <v-col
        cols="6"
        md="8"
      >
        <a
          v-if="detail.mp3Url && (detail.mp3Url !== null || detail.mp3Url !== '')"
          :href="detail.mp3Url"
          target="_blank"
        > open </a>
      </v-col>

      <v-col
        cols="6"
        md="4"
      >
        <span class="font-weight-bold">
          Text
        </span>
      </v-col>
      <v-col
        cols="6"
        md="8"
      >
        <a
          v-if="detail.resultUrl && (detail.resultUrl !== null || detail.resultUrl !== '')"
          :href="detail.resultUrl"
          target="_blank"
        > open </a>
      </v-col>

      <v-col
        cols="6"
        md="4"
      >
        <span class="font-weight-bold">
          Note
        </span>
      </v-col>
      <v-col
        cols="6"
        md="8"
      >
        <p class="mb-0">
          {{ detail.note }}
        </p>
      </v-col>

      <v-col
        cols="6"
        md="4"
      >
        <span class="font-weight-bold">
          Tags
        </span>
      </v-col>
      <v-col
        cols="6"
        md="8"
      >
        <v-chip-group
          active-class="primary--text"
          column
        >
          <v-chip
            v-for="(tag, index) in detail.tags"
            :key="`${tag}-${index}`"
            color="primary"
          >
            {{ tag }}
          </v-chip>
        </v-chip-group>
      </v-col>

      <v-col>
        <v-btn
          color="error"
          :loading="loading"
          @click="modalConfirm = true"
        >
          Delete
        </v-btn>
        <v-btn
          class="ml-4"
          color="warning"
          :loading="loading"
          @click="$router.push({ name: 'update', params: { id }})"
        >
          Update
        </v-btn>
      </v-col>
    </v-row>
    <confirm-modal
      :active.sync="modalConfirm"
      title="Delete"
      show-cancel
      :message="`Confirm delete ${detail.title}`"
      @onCancel="modalConfirm = false"
      @onConfirm="confirmDelete"
    />
  </v-card>
</template>

<script>
import humps from 'humps'
import MasterDataProvider from '@/resources/master-data'
import TrackProvider from '@/resources/track'
import confirmModal from '@/components/ModalConfirm.vue'

const MasterDataService = new MasterDataProvider()
const TrackService = new TrackProvider()
export default {
  components: {
    confirmModal
  },
  data() {
    return {
      modalConfirm: false,
      loading: false,
      detail: {},
      langs: {}
    }
  },
  computed: {
    id() {
      return this.$route.params.id || null
    }
  },
  async mounted() {
    await this.getlang()
    await this.getData()
  },
  methods: {
    async getlang() {
      const { data } = await MasterDataService.getLangs()
      this.langs = data
    },
    async getData() {
      const { data } = await TrackService.getTrackById(this.id)
      this.detail = humps.camelizeKeys(data)
    },
    async confirmDelete() {
      try {
        this.loading = true
        const result = await TrackService.deleteTrack(this.id)
        if (result) {
          this.$store.dispatch('Style/setSnackbar', {
            message: 'Success',
            type: 'success',
            active: true
          })

          this.$router.push({ name: 'trackList' })
        }
      } catch (err) {
        this.$store.dispatch('Style/setSnackbar', {
          message: `Error ${err.message}`,
          type: 'pink',
          active: true
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
